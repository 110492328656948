import PropTypes from 'prop-types';
import ReactMarkdown from 'react-markdown';
import { Card, CardContent } from '../components/ui/card';
import ShareButtons from './ShareButtons';
import { estimateReadingTime } from '../lib/utils';

const MarkdownArticleViewer = ({ article, isLoading }) => {
  if (isLoading) {
    return (
      <Card className="max-w-4xl mx-auto bg-white dark:bg-gray-800 animate-pulse">
        <CardContent className="p-6">
          <div className="h-64 bg-gray-200 dark:bg-gray-700 rounded-lg mb-6" />
          <div className="h-8 bg-gray-200 dark:bg-gray-700 rounded w-3/4 mb-6" />
          <div className="space-y-4">
            <div className="h-4 bg-gray-200 dark:bg-gray-700 rounded w-full" />
            <div className="h-4 bg-gray-200 dark:bg-gray-700 rounded w-5/6" />
            <div className="h-4 bg-gray-200 dark:bg-gray-700 rounded w-4/6" />
          </div>
        </CardContent>
      </Card>
    );
  }

  if (!article) {
    return (
      <Card className="max-w-4xl mx-auto bg-white dark:bg-gray-800">
        <CardContent className="p-6">
          <p className="text-center text-gray-500 dark:text-gray-400">
            Article not found or failed to load.
          </p>
        </CardContent>
      </Card>
    );
  }

  const readingTime = article.content ? estimateReadingTime(article.content) : 0;

  return (
    <Card className="max-w-4xl mx-auto bg-white dark:bg-gray-800">
      <CardContent className="p-6">
        {article.img_url && (
          <img 
            src={article.img_url} 
            alt={article.title || ''}
            className="w-full h-64 object-cover mb-6 rounded-lg"
            loading="lazy"
          />
        )}
        
        <div className="flex justify-between items-center mb-6">
          <div className="text-sm text-gray-500 dark:text-gray-400">
            {article.rss_feed_id && (
              <>
                <span>Feed ID: {article.rss_feed_id}</span>
                <span className="mx-2">·</span>
              </>
            )}
            <span>{readingTime} min read</span>
          </div>
          <ShareButtons article={article} />
        </div>

        <h1 className="text-3xl font-bold mb-6 text-gray-900 dark:text-gray-100">
          {article.title || 'Untitled'}
        </h1>

        {article.content ? (
          <div className="prose prose-lg max-w-none dark:prose-invert prose-headings:text-gray-900 dark:prose-headings:text-gray-100 prose-p:text-gray-700 dark:prose-p:text-gray-300 prose-a:text-blue-600 dark:prose-a:text-blue-400 hover:prose-a:text-blue-800 dark:hover:prose-a:text-blue-300">
            <ReactMarkdown
              components={{
                h1: ({ node, ...props }) => (
                  <h1 className="text-2xl font-bold mt-8 mb-4" {...props} />
                ),
                h2: ({ node, ...props }) => (
                  <h2 className="text-xl font-bold mt-6 mb-3" {...props} />
                ),
                p: ({ node, ...props }) => (
                  <p className="mb-4 leading-relaxed" {...props} />
                ),
                img: ({ node, alt, src, ...props }) => (
                  <img 
                    src={src}
                    alt={alt || ''}
                    className="rounded-lg"
                    loading="lazy"
                    {...props}
                  />
                ),
                a: ({ node, href, children, ...props }) => (
                  <a
                    href={href}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="transition-colors duration-200"
                    {...props}
                  >
                    {children}
                  </a>
                ),
              }}
            >
              {article.content}
            </ReactMarkdown>
          </div>
        ) : (
          <p className="text-center text-gray-500 dark:text-gray-400">
            No content available.
          </p>
        )}
      </CardContent>
    </Card>
  );
};

MarkdownArticleViewer.propTypes = {
  article: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    title: PropTypes.string,
    content: PropTypes.string,
    img_url: PropTypes.string,
    rss_feed_id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  }),
  isLoading: PropTypes.bool,
};

MarkdownArticleViewer.defaultProps = {
  isLoading: false,
};

export default MarkdownArticleViewer;
