import React from 'react';

const About = () => {
  return (
    <div className="max-w-3xl mx-auto px-4 py-8">
      <h1 className="text-4xl font-bold mb-6 bg-gradient-to-r from-blue-600 to-blue-400 dark:from-blue-400 dark:to-blue-300 text-transparent bg-clip-text">
        About SN TL;DR
      </h1>
      
      <div className="prose dark:prose-invert max-w-none">
        <p className="text-lg mb-6">
          SN TL;DR is an innovative ServiceNow news platform that leverages artificial intelligence to bring you the latest updates, insights, and developments from across the ServiceNow ecosystem. We aggregate and process news from various trusted sources to keep you informed about everything ServiceNow.
        </p>

        <h2 className="text-2xl font-semibold mb-4">How It Works</h2>
        <p className="mb-6">
          Our AI-powered system continuously monitors and analyzes multiple ServiceNow news sources, transforming lengthy articles into clear, concise summaries. This means you get the essential information without the fluff, saving you valuable time while staying up-to-date with the ServiceNow world.
        </p>

        <h2 className="text-2xl font-semibold mb-4">What We Cover</h2>
        <ul className="list-disc pl-6 mb-6">
          <li>Latest ServiceNow platform updates and releases</li>
          <li>Industry news and trends</li>
          <li>Product announcements and feature rollouts</li>
          <li>Community highlights and developments</li>
          <li>Best practices and implementation insights</li>
        </ul>

        <h2 className="text-2xl font-semibold mb-4">AI-Powered Intelligence</h2>
        <p className="mb-6">
          Our advanced AI technology doesn't just collect news—it understands it. By processing information from multiple sources, our system can identify key trends, correlate related updates, and present information in a way that's both comprehensive and easy to digest.
        </p>

        <h2 className="text-2xl font-semibold mb-4">Stay Informed</h2>
        <p>
          Whether you're a ServiceNow administrator, developer, or business user, SN TL;DR helps you stay current with the platform's rapid evolution. Browse our AI-curated articles to get quick, accurate insights into the latest ServiceNow developments and never miss an important update.
        </p>
      </div>
    </div>
  );
};

export default About;
