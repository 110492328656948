import React from 'react';
import PropTypes from 'prop-types';
import { Button } from './ui/button';

const KeywordFilter = ({ keywords, selectedKeywords, onKeywordToggle }) => {
  if (!keywords || keywords.length === 0) return null;

  return (
    <div className="flex flex-wrap gap-2 mb-4">
      {keywords.map((keyword) => (
        <Button
          key={keyword}
          variant={selectedKeywords.includes(keyword) ? "secondary" : "outline"}
          size="sm"
          onClick={() => onKeywordToggle(keyword)}
          className={`
            ${selectedKeywords.includes(keyword) 
              ? 'bg-blue-100 dark:bg-blue-900 text-blue-700 dark:text-blue-300 hover:bg-blue-200 dark:hover:bg-blue-800' 
              : 'text-gray-700 dark:text-gray-200 hover:bg-gray-100 dark:hover:bg-gray-800'
            }
            transition-colors duration-200
          `}
        >
          {keyword}
          {selectedKeywords.includes(keyword) && (
            <span className="ml-2 text-xs">×</span>
          )}
        </Button>
      ))}
      {selectedKeywords.length > 0 && (
        <Button
          variant="ghost"
          size="sm"
          onClick={() => selectedKeywords.forEach(keyword => onKeywordToggle(keyword))}
          className="text-gray-500 hover:text-gray-700 dark:text-gray-400 dark:hover:text-gray-200"
        >
          Clear filters
        </Button>
      )}
    </div>
  );
};

KeywordFilter.propTypes = {
  keywords: PropTypes.arrayOf(PropTypes.string).isRequired,
  selectedKeywords: PropTypes.arrayOf(PropTypes.string).isRequired,
  onKeywordToggle: PropTypes.func.isRequired
};

export default KeywordFilter;
