import React from 'react';
import PropTypes from 'prop-types';
import { Search } from 'lucide-react';
import { Input } from '../components/ui/input';

const SearchBar = ({ 
  onSearch, 
  value = '', 
  placeholder = 'Search articles...', 
  disabled = false,
  className = ''
}) => {
  const handleKeyDown = (e) => {
    if (e.key === 'Escape') {
      e.target.blur();
      onSearch('');
    }
  };

  return (
    <div className={`relative ${className}`}>
      <Search 
        className={`absolute left-3 top-1/2 transform -translate-y-1/2 h-4 w-4 
          ${disabled 
            ? 'text-gray-300 dark:text-gray-700' 
            : 'text-gray-400 dark:text-gray-600'
          }`}
        aria-hidden="true"
      />
      <Input
        type="search"
        className="pl-10"
        placeholder={placeholder}
        value={value}
        onChange={(e) => onSearch(e.target.value)}
        onKeyDown={handleKeyDown}
        disabled={disabled}
        aria-label="Search articles"
        role="searchbox"
      />
    </div>
  );
};

SearchBar.propTypes = {
  onSearch: PropTypes.func.isRequired,
  value: PropTypes.string,
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
  className: PropTypes.string
};

export default SearchBar;
