import { useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Card, CardContent } from '../components/ui/card';
import { estimateReadingTime } from '../lib/utils';

const ArticleCard = ({ article }) => {
  const [imageError, setImageError] = useState(false);
  const readingTime = article.content ? estimateReadingTime(article.content) : 0;

  // Format the preview text properly
  const getPreviewText = (content) => {
    if (!content) return '';
    
    try {
      // Remove markdown syntax
      const cleanContent = content.replace(/[#*`_~\[\]]/g, '');
      // Get first few sentences
      const sentences = cleanContent.split(/[.!?]/).slice(0, 2);
      return sentences.join('.').trim() + '...';
    } catch (error) {
      console.error('Error processing content:', error);
      return '';
    }
  };

  // Format date if available
  const formatDate = (dateString) => {
    if (!dateString) return null;
    try {
      return new Date(dateString).toLocaleDateString('en-US', {
        year: 'numeric',
        month: 'short',
        day: 'numeric'
      });
    } catch (error) {
      console.error('Error formatting date:', error);
      return null;
    }
  };

  return (
    <Card className="group h-full flex flex-col hover:shadow-lg transition-all duration-300">
      <CardContent className="p-6 flex flex-col h-full">
        <Link 
          to={`/article/${article.id}`}
          className="flex flex-col h-full focus:outline-none focus:ring-2 focus:ring-blue-500 rounded-lg"
          aria-label={`Read article: ${article.title || 'Untitled'}`}
        >
          {article.img_url && !imageError && (
            <div className="relative w-full h-48 mb-4 overflow-hidden rounded-lg bg-gray-100 dark:bg-gray-800">
              <img 
                src={article.img_url} 
                alt=""
                className="w-full h-full object-cover transform group-hover:scale-105 transition-transform duration-300"
                loading="lazy"
                onError={() => setImageError(true)}
                aria-hidden="true"
              />
            </div>
          )}

          <div className="flex-grow">
            <h2 className="text-xl font-bold mb-2 text-gray-900 dark:text-gray-100 group-hover:text-blue-600 dark:group-hover:text-blue-400 transition-colors duration-200">
              {article.title || 'Untitled'}
            </h2>
            {article.content && (
              <p className="text-gray-600 dark:text-gray-300 mb-4 line-clamp-3">
                {getPreviewText(article.content)}
              </p>
            )}
          </div>

          {article.keywords && article.keywords.length > 0 && (
            <div className="flex flex-wrap gap-2 mb-4">
              {article.keywords.map((keyword) => (
                <span
                  key={keyword}
                  className="text-xs px-2 py-1 rounded-full bg-gray-100 dark:bg-gray-800 text-gray-600 dark:text-gray-300"
                >
                  {keyword}
                </span>
              ))}
            </div>
          )}

          <div className="flex flex-wrap items-center gap-2 text-sm text-gray-500 dark:text-gray-400 mt-auto">
            {article.created_at && (
              <>
                <span>{formatDate(article.created_at)}</span>
                <span className="mx-2">·</span>
              </>
            )}
            {article.rss_feed_id && (
              <>
                <span>Feed ID: {article.rss_feed_id}</span>
                <span className="mx-2">·</span>
              </>
            )}
            <span>{readingTime} min read</span>
          </div>
        </Link>
      </CardContent>
    </Card>
  );
};

ArticleCard.propTypes = {
  article: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    title: PropTypes.string,
    content: PropTypes.string,
    img_url: PropTypes.string,
    rss_feed_id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    created_at: PropTypes.string,
    keywords: PropTypes.arrayOf(PropTypes.string)
  }).isRequired
};

export default ArticleCard;
