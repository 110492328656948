import { useState, useEffect, useCallback } from 'react';
import ArticleCard from '../components/ArticleCard';
import SearchBar from '../components/SearchBar';
import KeywordFilter from '../components/KeywordFilter';
import { fetchFromSupabase, checkSupabaseConnection, getTableSchema } from '../lib/supabase';
import { Card } from '../components/ui/card';
import { debounce } from '../lib/utils';

// Skeleton loader for articles
const ArticleSkeleton = () => (
  <Card className="h-[360px] animate-pulse">
    <div className="h-48 bg-gray-200 dark:bg-gray-700 rounded-t-lg" />
    <div className="p-4 space-y-4">
      <div className="h-4 bg-gray-200 dark:bg-gray-700 rounded w-3/4" />
      <div className="h-4 bg-gray-200 dark:bg-gray-700 rounded w-1/2" />
      <div className="h-4 bg-gray-200 dark:bg-gray-700 rounded w-5/6" />
    </div>
  </Card>
);

const LoadingGrid = () => (
  <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
    {[...Array(6)].map((_, i) => (
      <ArticleSkeleton key={i} />
    ))}
  </div>
);

const Articles = () => {
  const [articles, setArticles] = useState([]);
  const [filteredArticles, setFilteredArticles] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [connectionStatus, setConnectionStatus] = useState(null);
  const [selectedKeywords, setSelectedKeywords] = useState([]);
  const [availableKeywords, setAvailableKeywords] = useState([]);

  const fetchArticles = async () => {
    try {
      setLoading(true);
      setError(null);

      const isConnected = await checkSupabaseConnection();
      setConnectionStatus(isConnected);

      if (!isConnected) {
        throw new Error('Unable to connect to the database');
      }

      const schema = await getTableSchema('ai_articles');
      console.log('Table schema:', schema);
      
      const data = await fetchFromSupabase('ai_articles', {
        select: '*',
        orderBy: {
          column: 'created_at',
          ascending: false
        }
      });

      if (!data) {
        throw new Error('No data received from the database');
      }

      // Extract unique keywords from all articles
      const keywords = new Set();
      data.forEach(article => {
        if (article.keywords && Array.isArray(article.keywords)) {
          article.keywords.forEach(keyword => keywords.add(keyword));
        }
      });
      setAvailableKeywords(Array.from(keywords).sort());

      setArticles(data);
      setFilteredArticles(data);
    } catch (err) {
      console.error('Error in fetchArticles:', err);
      setError(err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchArticles();
  }, []);

  // Filter articles based on search term and selected keywords
  const filterArticles = useCallback((articles, searchTerm, selectedKeywords) => {
    return articles.filter(article => {
      // Search term filter
      const searchMatch = !searchTerm.trim() || 
        article.title?.toLowerCase().includes(searchTerm.toLowerCase()) ||
        article.content?.toLowerCase().includes(searchTerm.toLowerCase());

      // Keywords filter
      const keywordMatch = selectedKeywords.length === 0 || 
        (article.keywords && selectedKeywords.every(keyword => 
          article.keywords.includes(keyword)
        ));

      return searchMatch && keywordMatch;
    });
  }, []);

  // Debounced search function
  const debouncedSearch = useCallback(
    debounce((searchTerm) => {
      const filtered = filterArticles(articles, searchTerm, selectedKeywords);
      setFilteredArticles(filtered);
    }, 300),
    [articles, selectedKeywords, filterArticles]
  );

  const handleSearch = (term) => {
    setSearchTerm(term);
    debouncedSearch(term);
  };

  const handleKeywordToggle = (keyword) => {
    setSelectedKeywords(prev => {
      const newKeywords = prev.includes(keyword)
        ? prev.filter(k => k !== keyword)
        : [...prev, keyword];
      
      const filtered = filterArticles(articles, searchTerm, newKeywords);
      setFilteredArticles(filtered);
      return newKeywords;
    });
  };

  if (error) {
    return (
      <div className="text-center py-12">
        <h2 className="text-xl font-semibold text-red-600 dark:text-red-400 mb-4">
          Failed to load articles
        </h2>
        <p className="text-gray-600 dark:text-gray-400 mb-4">
          {error.message || 'There was a problem loading the articles. Please try again later.'}
        </p>
        {connectionStatus === false && (
          <p className="text-sm text-gray-500 dark:text-gray-400 mb-4">
            Database connection failed. Please check your connection and try again.
          </p>
        )}
        <button
          onClick={fetchArticles}
          className="text-blue-600 dark:text-blue-400 hover:underline"
        >
          Try again
        </button>
      </div>
    );
  }

  return (
    <div className="container mx-auto px-4 py-8 space-y-8">
      <div className="mb-8">
        <SearchBar 
          onSearch={handleSearch} 
          value={searchTerm}
          placeholder="Search articles..."
          disabled={loading}
        />
      </div>
      
      {loading ? (
        <LoadingGrid />
      ) : filteredArticles.length === 0 ? (
        <div className="text-center py-12">
          <p className="text-gray-500 dark:text-gray-400">
            {searchTerm || selectedKeywords.length > 0 
              ? 'No articles found matching your criteria' 
              : 'No articles available'
            }
          </p>
          {!searchTerm && selectedKeywords.length === 0 && (
            <button
              onClick={fetchArticles}
              className="mt-4 text-blue-600 dark:text-blue-400 hover:underline"
            >
              Refresh articles
            </button>
          )}
        </div>
      ) : (
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
          {filteredArticles.map((article) => (
            <ArticleCard key={article.id} article={article} />
          ))}
        </div>
      )}

      {availableKeywords.length > 0 && (
        <div className="mt-12 pt-8 border-t border-gray-200 dark:border-gray-700">
          <h2 className="text-xl font-semibold mb-4">Article Keywords</h2>
          <KeywordFilter
            keywords={availableKeywords}
            selectedKeywords={selectedKeywords}
            onKeywordToggle={handleKeywordToggle}
          />
        </div>
      )}
    </div>
  );
};

export default Articles;
