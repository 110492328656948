import { useState } from 'react';
import PropTypes from 'prop-types';
import { Twitter, Facebook, Linkedin, Link as LinkIcon, Check } from 'lucide-react';
import { Button } from '../components/ui/button';

const ShareButtons = ({ article }) => {
  const [copied, setCopied] = useState(false);
  const url = window.location.href;
  const title = article?.title ? encodeURIComponent(article.title) : '';

  const shareLinks = {
    twitter: `https://twitter.com/intent/tweet?url=${url}&text=${title}`,
    facebook: `https://www.facebook.com/sharer/sharer.php?u=${url}`,
    linkedin: `https://www.linkedin.com/shareArticle?mini=true&url=${url}&title=${title}`,
  };

  const handleShare = async (platform) => {
    try {
      const shareWindow = window.open(shareLinks[platform], '_blank', 'noopener,noreferrer');
      if (shareWindow) {
        shareWindow.opener = null;
      }
    } catch (error) {
      console.error(`Error sharing to ${platform}:`, error);
      // Fallback to navigator.share if available
      if (navigator.share) {
        try {
          await navigator.share({
            title: article?.title || 'Shared Article',
            url: url
          });
        } catch (shareError) {
          console.error('Error using navigator.share:', shareError);
        }
      }
    }
  };

  const copyLink = async () => {
    try {
      if (navigator.clipboard) {
        await navigator.clipboard.writeText(url);
        setCopied(true);
        setTimeout(() => setCopied(false), 2000);
      } else {
        // Fallback for browsers that don't support clipboard API
        const textarea = document.createElement('textarea');
        textarea.value = url;
        textarea.style.position = 'fixed'; // Avoid scrolling
        document.body.appendChild(textarea);
        textarea.focus();
        textarea.select();
        try {
          document.execCommand('copy');
          setCopied(true);
          setTimeout(() => setCopied(false), 2000);
        } catch (err) {
          console.error('Fallback copy failed:', err);
        }
        document.body.removeChild(textarea);
      }
    } catch (error) {
      console.error('Copy failed:', error);
    }
  };

  return (
    <div 
      className="flex items-center space-x-2" 
      role="group" 
      aria-label="Share article"
    >
      <Button
        variant="outline"
        size="icon"
        onClick={() => handleShare('twitter')}
        aria-label="Share on Twitter"
        className="hover:bg-[#1DA1F2] hover:text-white focus:ring-2 focus:ring-[#1DA1F2] focus:ring-offset-2"
      >
        <Twitter className="h-4 w-4" />
      </Button>
      <Button
        variant="outline"
        size="icon"
        onClick={() => handleShare('facebook')}
        aria-label="Share on Facebook"
        className="hover:bg-[#4267B2] hover:text-white focus:ring-2 focus:ring-[#4267B2] focus:ring-offset-2"
      >
        <Facebook className="h-4 w-4" />
      </Button>
      <Button
        variant="outline"
        size="icon"
        onClick={() => handleShare('linkedin')}
        aria-label="Share on LinkedIn"
        className="hover:bg-[#0077B5] hover:text-white focus:ring-2 focus:ring-[#0077B5] focus:ring-offset-2"
      >
        <Linkedin className="h-4 w-4" />
      </Button>
      <Button
        variant="outline"
        size="icon"
        onClick={copyLink}
        aria-label={copied ? "Link copied!" : "Copy link"}
        className="focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 transition-colors duration-200"
      >
        {copied ? (
          <Check className="h-4 w-4 text-green-500" />
        ) : (
          <LinkIcon className="h-4 w-4" />
        )}
      </Button>
    </div>
  );
};

ShareButtons.propTypes = {
  article: PropTypes.shape({
    title: PropTypes.string,
    // Add other article properties that might be needed
  }),
};

ShareButtons.defaultProps = {
  article: {
    title: 'Shared Article'
  }
};

export default ShareButtons;
