import { clsx } from "clsx";
import { twMerge } from "tailwind-merge";

export function cn(...inputs) {
  return twMerge(clsx(inputs));
}

export function debounce(func, wait) {
  let timeout;
  return function executedFunction(...args) {
    const later = () => {
      clearTimeout(timeout);
      func(...args);
    };
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
  };
}

export function estimateReadingTime(text) {
  const wordsPerMinute = 200;
  const wordCount = text.trim().split(/\s+/).length;
  const readingTime = Math.ceil(wordCount / wordsPerMinute);
  return readingTime;
}

export function extractKeywordsFromContent(content) {
  if (!content) return [];

  // Look for keywords section at the end of the content with various formats
  const keywordsPattern = /(?:\*\*(?:Main\s+)?Keywords(?:\/tags)?:\*\*|(?:Main\s+)?Keywords(?:\/tags)?:)[\s]*([\s\S]*?)(?:\n\s*\n|$)/i;
  const keywordsMatch = content.match(keywordsPattern);
  
  if (!keywordsMatch) return [];

  // Split the keywords by commas and clean them up
  const keywordsSet = new Set();
  const normalizedMap = new Map();

  keywordsMatch[1]
    .split(',')
    .map(keyword => keyword.trim())
    .filter(keyword => keyword.length > 0)
    .forEach(keyword => {
      // Remove trailing period and normalize case for comparison
      const normalizedKeyword = keyword.replace(/\.$/, '').toLowerCase();
      
      // If we haven't seen this normalized form before, or if this version is "better"
      // (no trailing period), update our map
      if (!normalizedMap.has(normalizedKeyword) || 
          keyword === normalizedKeyword) {
        normalizedMap.set(normalizedKeyword, keyword);
      }
    });

  // Convert back to array, maintaining the original casing of the chosen keywords
  return Array.from(normalizedMap.values());
}
