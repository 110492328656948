import { useState, useEffect, useCallback } from 'react';
import ArticleCard from '../components/ArticleCard';
import SearchBar from '../components/SearchBar';
import KeywordFilter from '../components/KeywordFilter';
import HeroSection from '../components/HeroSection';
import { fetchFromSupabase, checkSupabaseConnection, getTableSchema } from '../lib/supabase';
import { Card } from '../components/ui/card';
import { Button } from '../components/ui/button';
import { debounce } from '../lib/utils';

// Skeleton loader for articles
const ArticleSkeleton = () => (
  <Card className="h-[360px] animate-pulse">
    <div className="h-48 bg-gray-200 dark:bg-gray-700 rounded-t-lg" />
    <div className="p-4 space-y-4">
      <div className="h-4 bg-gray-200 dark:bg-gray-700 rounded w-3/4" />
      <div className="h-4 bg-gray-200 dark:bg-gray-700 rounded w-1/2" />
      <div className="h-4 bg-gray-200 dark:bg-gray-700 rounded w-5/6" />
    </div>
  </Card>
);

const LoadingGrid = () => (
  <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
    {[...Array(6)].map((_, i) => (
      <ArticleSkeleton key={i} />
    ))}
  </div>
);

const Home = () => {
  const [articles, setArticles] = useState([]);
  const [filteredArticles, setFilteredArticles] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [connectionStatus, setConnectionStatus] = useState(null);
  const [selectedKeywords, setSelectedKeywords] = useState([]);
  const [availableKeywords, setAvailableKeywords] = useState([]);
  const [showAllKeywords, setShowAllKeywords] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const articlesPerPage = 15;
  const initialKeywordLimit = 15;

  const fetchArticles = async () => {
    try {
      setLoading(true);
      setError(null);

      const isConnected = await checkSupabaseConnection();
      setConnectionStatus(isConnected);

      if (!isConnected) {
        throw new Error('Unable to connect to the database');
      }

      const schema = await getTableSchema('ai_articles');
      console.log('Table schema:', schema);
      
      const data = await fetchFromSupabase('ai_articles', {
        select: '*',
        orderBy: {
          column: 'created_at',
          ascending: false
        }
      });

      if (!data) {
        throw new Error('No data received from the database');
      }

      // Extract keywords and count their frequency
      const keywordFrequency = new Map();
      data.forEach(article => {
        if (article.keywords && Array.isArray(article.keywords)) {
          article.keywords.forEach(keyword => {
            keywordFrequency.set(keyword, (keywordFrequency.get(keyword) || 0) + 1);
          });
        }
      });

      // Sort keywords by frequency
      const sortedKeywords = Array.from(keywordFrequency.entries())
        .sort((a, b) => b[1] - a[1])
        .map(([keyword]) => keyword);

      setAvailableKeywords(sortedKeywords);
      setArticles(data);
      setFilteredArticles(data);
    } catch (err) {
      console.error('Error in fetchArticles:', err);
      setError(err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchArticles();
  }, []);

  // Filter articles based on search term and selected keywords
  const filterArticles = useCallback((articles, searchTerm, selectedKeywords) => {
    return articles.filter(article => {
      // Search term filter
      const searchMatch = !searchTerm.trim() || 
        article.title?.toLowerCase().includes(searchTerm.toLowerCase()) ||
        article.content?.toLowerCase().includes(searchTerm.toLowerCase());

      // Keywords filter
      const keywordMatch = selectedKeywords.length === 0 || 
        (article.keywords && selectedKeywords.every(keyword => 
          article.keywords.includes(keyword)
        ));

      return searchMatch && keywordMatch;
    });
  }, []);

  // Debounced search function
  const debouncedSearch = useCallback(
    debounce((searchTerm) => {
      const filtered = filterArticles(articles, searchTerm, selectedKeywords);
      setFilteredArticles(filtered);
      setCurrentPage(1); // Reset to first page on new search
    }, 300),
    [articles, selectedKeywords, filterArticles]
  );

  const handleSearch = (term) => {
    setSearchTerm(term);
    debouncedSearch(term);
  };

  const handleKeywordToggle = (keyword) => {
    setSelectedKeywords(prev => {
      const newKeywords = prev.includes(keyword)
        ? prev.filter(k => k !== keyword)
        : [...prev, keyword];
      
      const filtered = filterArticles(articles, searchTerm, newKeywords);
      setFilteredArticles(filtered);
      setCurrentPage(1); // Reset to first page on keyword filter change
      return newKeywords;
    });
  };

  // Get current page's articles
  const indexOfLastArticle = currentPage * articlesPerPage;
  const indexOfFirstArticle = indexOfLastArticle - articlesPerPage;
  const currentArticles = filteredArticles.slice(indexOfFirstArticle, indexOfLastArticle);
  const totalPages = Math.ceil(filteredArticles.length / articlesPerPage);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  // Get displayed keywords based on showAllKeywords state
  const displayedKeywords = showAllKeywords 
    ? availableKeywords 
    : availableKeywords.slice(0, initialKeywordLimit);

  if (error) {
    return (
      <div className="text-center py-12">
        <h2 className="text-xl font-semibold text-red-600 dark:text-red-400 mb-4">
          Failed to load articles
        </h2>
        <p className="text-gray-600 dark:text-gray-400 mb-4">
          {error.message || 'There was a problem loading the articles. Please try again later.'}
        </p>
        {connectionStatus === false && (
          <p className="text-sm text-gray-500 dark:text-gray-400 mb-4">
            Database connection failed. Please check your connection and try again.
          </p>
        )}
        <button
          onClick={fetchArticles}
          className="text-blue-600 dark:text-blue-400 hover:underline"
        >
          Try again
        </button>
      </div>
    );
  }

  return (
    <div className="space-y-8">
      <HeroSection />
      
      <div className="container mx-auto px-4">
        <div className="mb-8">
          <SearchBar 
            onSearch={handleSearch} 
            value={searchTerm}
            placeholder="Search articles..."
            disabled={loading}
          />
        </div>
        
        {availableKeywords.length > 0 && (
          <div className="mt-12 pt-8 border-t border-gray-200 dark:border-gray-700">
            <h2 className="text-xl font-semibold mb-4 text-gray-700 dark:text-gray-200">Popular Keywords</h2>
            <KeywordFilter
              keywords={displayedKeywords}
              selectedKeywords={selectedKeywords}
              onKeywordToggle={handleKeywordToggle}
            />
            {availableKeywords.length > initialKeywordLimit && (
              <Button
                variant="ghost"
                size="sm"
                onClick={() => setShowAllKeywords(!showAllKeywords)}
                className="mt-4 text-blue-600 dark:text-blue-400"
              >
                {showAllKeywords ? 'Show Less' : `Show More (${availableKeywords.length - initialKeywordLimit} more)`}
              </Button>
            )}
          </div>
        )}
        
        {loading ? (
          <LoadingGrid />
        ) : filteredArticles.length === 0 ? (
          <div className="text-center py-12">
            <p className="text-gray-500 dark:text-gray-400">
              {searchTerm || selectedKeywords.length > 0 
                ? 'No articles found matching your criteria' 
                : 'No articles available'
              }
            </p>
            {!searchTerm && selectedKeywords.length === 0 && (
              <button
                onClick={fetchArticles}
                className="mt-4 text-blue-600 dark:text-blue-400 hover:underline"
              >
                Refresh articles
              </button>
            )}
          </div>
        ) : (
          <>
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
              {currentArticles.map((article) => (
                <ArticleCard key={article.id} article={article} />
              ))}
            </div>

            {/* Pagination Controls */}
            {totalPages > 1 && (
              <div className="flex justify-center items-center gap-2 mt-8">
                <Button
                  variant="outline"
                  size="sm"
                  onClick={() => handlePageChange(currentPage - 1)}
                  disabled={currentPage === 1}
                >
                  Previous
                </Button>
                
                {[...Array(totalPages)].map((_, index) => (
                  <Button
                    key={index + 1}
                    variant={currentPage === index + 1 ? "default" : "outline"}
                    size="sm"
                    onClick={() => handlePageChange(index + 1)}
                  >
                    {index + 1}
                  </Button>
                ))}

                <Button
                  variant="outline"
                  size="sm"
                  onClick={() => handlePageChange(currentPage + 1)}
                  disabled={currentPage === totalPages}
                >
                  Next
                </Button>
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default Home;
