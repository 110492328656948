const HeroSection = () => {
  return (
    <div className="relative h-[400px] w-full mb-8 overflow-hidden">
      {/* Background image with overlay */}
      <div 
        className="absolute inset-0 bg-cover bg-center"
        style={{
          backgroundImage: "url('/robot-hero.png')",
        }}
      >
        {/* Dark overlay */}
        <div className="absolute inset-0 bg-black/50"></div>
      </div>
      
      {/* Content */}
      <div className="relative h-full container mx-auto px-4 flex flex-col justify-center items-center text-center">
        <h1 className="text-4xl md:text-5xl lg:text-6xl font-bold text-white mb-4">
          Welcome to SN TL;DR
        </h1>
        <p className="text-xl md:text-2xl text-gray-200 max-w-2xl">
          Get the latest ServiceNow news from your friendly neighborhood GenAI.
        </p>
      </div>
    </div>
  );
};

export default HeroSection;
