import { Component, useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import MarkdownArticleViewer from '../components/MarkdownArticleViewer';
import { fetchFromSupabase } from '../lib/supabase';
import { Card, CardContent } from '../components/ui/card';

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false, error: null };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true, error };
  }

  render() {
    if (this.state.hasError) {
      return (
        <Card className="max-w-4xl mx-auto mt-8">
          <CardContent className="p-6">
            <h2 className="text-xl font-semibold text-red-600 dark:text-red-400 mb-2">
              Something went wrong
            </h2>
            <p className="text-gray-600 dark:text-gray-400 mb-4">
              {this.state.error?.message || 'An unexpected error occurred'}
            </p>
            <button
              onClick={() => window.location.reload()}
              className="text-blue-600 dark:text-blue-400 hover:underline"
            >
              Try again
            </button>
          </CardContent>
        </Card>
      );
    }

    return this.props.children;
  }
}

const Article = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [article, setArticle] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchArticle = async () => {
      try {
        setLoading(true);
        setError(null);
        
        const data = await fetchFromSupabase('ai_articles', {
          filters: { id },
          select: '*'
        });

        if (!data || data.length === 0) {
          throw new Error('Article not found');
        }

        setArticle(data[0]);
      } catch (err) {
        setError(err);
        console.error('Error fetching article:', err);
      } finally {
        setLoading(false);
      }
    };

    if (id) {
      fetchArticle();
    }
  }, [id]);

  if (error) {
    return (
      <Card className="max-w-4xl mx-auto mt-8">
        <CardContent className="p-6">
          <div className="text-center">
            <h2 className="text-xl font-semibold text-red-600 dark:text-red-400 mb-4">
              {error.message === 'Article not found' ? 'Article not found' : 'Failed to load article'}
            </h2>
            <p className="text-gray-600 dark:text-gray-400 mb-4">
              {error.message === 'Article not found' 
                ? "The article you're looking for doesn't exist or has been removed."
                : 'There was a problem loading this article. Please try again later.'}
            </p>
            <button
              onClick={() => navigate('/')}
              className="text-blue-600 dark:text-blue-400 hover:underline"
            >
              Return to Home
            </button>
          </div>
        </CardContent>
      </Card>
    );
  }

  return (
    <ErrorBoundary>
      <div className="container mx-auto px-4 py-8">
        <MarkdownArticleViewer 
          article={article} 
          isLoading={loading}
        />
      </div>
    </ErrorBoundary>
  );
};

export default Article;
