import { createClient } from '@supabase/supabase-js';
import { extractKeywordsFromContent } from './utils';

const supabaseUrl = import.meta.env.VITE_SUPABASE_URL;
const supabaseKey = import.meta.env.VITE_SUPABASE_ANON_KEY;

if (!supabaseUrl || !supabaseKey) {
  throw new Error('Missing Supabase environment variables. Please check your .env file.');
}

export const supabase = createClient(supabaseUrl, supabaseKey);

export const handleSupabaseError = (error, context = {}) => {
  const errorDetails = {
    message: error.message,
    code: error.code,
    details: error.details,
    hint: error.hint,
    ...context,
  };
  
  console.error('Supabase error:', errorDetails);
  throw new Error(error.message || 'An error occurred while accessing the database');
};

export async function fetchFromSupabase(tableName, options = {}) {
  const DEBUG = true;

  try {
    if (DEBUG) {
      console.log('Fetching from table:', tableName);
      console.log('Options:', options);
    }

    let query = supabase.from(tableName).select(options.select || '*');

    if (options.filters) {
      Object.entries(options.filters).forEach(([key, value]) => {
        query = query.eq(key, value);
        if (DEBUG) {
          console.log(`Adding filter: ${key} = ${value}`);
        }
      });
    }

    if (options.orderBy) {
      query = query.order(options.orderBy.column, { ascending: options.orderBy.ascending });
      if (DEBUG) {
        console.log(`Adding order by: ${options.orderBy.column} ${options.orderBy.ascending ? 'ASC' : 'DESC'}`);
      }
    }

    if (DEBUG) {
      console.log('Executing query...');
    }

    const { data, error, status } = await query;

    if (error) {
      handleSupabaseError(error, {
        table: tableName,
        options,
        status,
      });
    }

    // Extract keywords from content
    if (tableName === 'ai_articles' && data) {
      const articlesWithKeywords = data.map(article => {
        if (article.content) {
          const keywords = extractKeywordsFromContent(article.content);
          return {
            ...article,
            keywords
          };
        }
        return {
          ...article,
          keywords: []
        };
      });

      if (DEBUG) {
        console.log('Articles with keywords:', articlesWithKeywords.map(article => ({
          id: article.id,
          keywords: article.keywords
        })));
      }

      return articlesWithKeywords;
    }

    return data;
  } catch (error) {
    handleSupabaseError(error, {
      table: tableName,
      options,
      type: 'Unexpected error',
    });
  }
}

export async function checkSupabaseConnection() {
  try {
    const { data, error } = await supabase.from('ai_articles').select('count');
    if (error) throw error;
    console.log('Supabase connection successful. Row count:', data);
    return true;
  } catch (error) {
    console.error('Supabase connection check failed:', error);
    return false;
  }
}

export async function getTableSchema(tableName) {
  try {
    const { data, error } = await supabase
      .from(tableName)
      .select('*')
      .limit(1);
    
    if (error) throw error;
    
    if (data && data[0]) {
      console.log(`Schema for ${tableName}:`, Object.keys(data[0]));
      return Object.keys(data[0]);
    }
    
    return null;
  } catch (error) {
    console.error(`Failed to get schema for ${tableName}:`, error);
    return null;
  }
}
